//
//
//
//
//
//

//import ServiceReport from "@/view/pages/service-form/Soled-Engineering/BATT_S1";
export default {
  /*components: {
    ServiceReport
  }*/
};
